$(document).ready(function () {
    // tooltip
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    // change button styles on click for google form btn
    $('#googleBtn').on("click", function(){
        $(this).removeClass('btn-bright-uct');
         $(this).addClass('btn-uct');
     });

    // refresh ama iframe on modal close event
    let amaModals = document.querySelectorAll('[id*="askMeAnything-teacher"]');
    $(function() {
        Array.from(amaModals).forEach(modal => {
            modal.addEventListener('hide.bs.modal', function (e) {
                let iframe = e.target.querySelector('iframe');
                if (iframe) {
                    iframe.src = iframe.src;
                };
            });
        });
    });
});

window.addEventListener('DOMContentLoaded', function() {
    let modalNode = document.getElementById('reenrolmentSurvey');

    if (!modalNode) {
        return;
    }

    const modal = new bootstrap.Modal(modalNode, {
        keyboard: false,
        backdrop: 'static',
    });
    modal.show();
});
